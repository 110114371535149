<template>
  <div v-if="chapter" :class="{ 'dark-mode': isDarkMode }" style="display: flex; justify-content: center;">
    <div class="control-bar">
      <button @click="togglePanel" class="menu-button">
        {{ isPanelVisible ? 'Close Menu' : 'Open Menu' }}
      </button>
      <span class="header-title" style="padding-right: 20px;">{{currentPage}}</span>
      <button @click="toggleDarkMode" class="mode-toggle">
          {{ isDarkMode ? '☀️' : '🌙' }}
        </button>
    </div>
    <div class="chapter-container">
        <div class="chapter-content" v-html="renderedContent"></div>
        <div class="navigation-buttons">
          <button @click="navigateChapter('prev')" :disabled="!hasPreviousChapter">Previous</button>
          <button @click="navigateChapter('next')" :disabled="!hasNextChapter">Next</button>
        </div>
    </div>
    <ScrollPanel id="menu-panel" v-show="isPanelVisible">
        <div style="display: flex; justify-content: flex-end">
            <button @click="togglePanel" class="close-button">X</button>
        </div>
        <router-link to="/" class="lesson-link">Home</router-link>
        <router-link :to="{ name: 'ChapterView', params: { chapterId: 'foreword' }}" class="lesson-link">
          Foreword
        </router-link>
        <router-link v-for="n in 9" :key="n" :to="{ name: 'ChapterView', params: { chapterId: n }}" class="lesson-link">
          Lesson {{ n }}
        </router-link>
    </ScrollPanel>
  </div>
  <div v-else>Loading chapter...</div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const chapter = ref(null);
const renderedContent = ref('');
const isPanelVisible = ref(true);
const isDarkMode = ref(false);
const currentPage = computed(() => {
  const pageId = route.params.chapterId;
  if (pageId === 'foreword') {
    return 'Foreword';
  } else {
    return `Lesson ${pageId.charAt(0).toUpperCase() + pageId.slice(1)}`;
  }
});

const togglePanel = () => {
  isPanelVisible.value = !isPanelVisible.value;
};

const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  localStorage.setItem('darkMode', isDarkMode.value);
};

const loadChapter = async () => {
  const chapterId = route.params.chapterId;
  try {
    // Dynamically import the chapter content as HTML
    const content = await import(`@/assets/chapters/${chapterId}.html?raw`);
    renderedContent.value = content.default; // Directly use the HTML content
    chapter.value = { 
      title: chapterId === 'foreword' ? 'Foreword' : `Chapter ${chapterId}`
    };
  } catch (error) {
    console.error('Error loading chapter content:', error);
    renderedContent.value = 'Error loading chapter content';
    chapter.value = null;
  }
};

const chapterOrder = ['foreword', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const currentChapterIndex = computed(() => chapterOrder.indexOf(route.params.chapterId));
const hasPreviousChapter = computed(() => currentChapterIndex.value > 0);
const hasNextChapter = computed(() => currentChapterIndex.value < chapterOrder.length - 1);

const navigateChapter = (direction) => {
  const newIndex = direction === 'next' ? currentChapterIndex.value + 1 : currentChapterIndex.value - 1;
  if (newIndex >= 0 && newIndex < chapterOrder.length) {
    router.push({ name: 'ChapterView', params: { chapterId: chapterOrder[newIndex] } }).then(() => {
      window.scrollTo(0, 0);
    });
  }
};

onMounted(() => {
  loadChapter();
  const savedMode = localStorage.getItem('darkMode');
  if (savedMode !== null) {
    isDarkMode.value = JSON.parse(savedMode);
  }
});
watch(() => route.params.chapterId, loadChapter);
</script>

<style>
.chapter-container {
    display: flex;
    flex-direction: column;
    width: 90%; /* Increased from 60% to be more responsive */
    max-width: 800px; /* Add a max-width for larger screens */
    margin: 0 auto; /* Center the container */
    margin-top: 20px;
    color: rgb(18, 18, 18);
    padding-top: 40px;
}

.chapter-content p {
  font-size: 1.2rem; /* Reduced from 1.5rem */
  text-align: left;
  font-family: 'Arial', 'Helvetica', sans-serif;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.chapter-content h1,
.chapter-content h2,
.chapter-content h3 {
  font-family: 'Georgia', 'Times New Roman', serif;
  margin-top: 1.5rem; /* Reduced from 2rem */
  margin-bottom: 0.75rem; /* Reduced from 1rem */
}

#menu-panel {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    left: 0px;
    width: 200px; /* Fixed width instead of percentage */
    height: 100%;
    padding-top: 10px;
    background-color: rgb(41 41 45);
    /* background: linear-gradient(to bottom, rgb(100 100 198), rgb(10 31 82)); */
    /* box-shadow: 2px 0px 20px black; */
}

.lesson-link {
    display: block;
    padding: 10px;
    font-size: 1.2rem; /* Reduced from 1.5rem */
    text-decoration: none;
    color: rgb(255, 255, 255);
}
.lesson-link:hover {
    color: rgb(137, 208, 250);
}

.close-button {
    display: block;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-end;
}

.close-button:hover {
    background-color: #d32f2f;
}

.control-bar {
    display: flex;
    justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 100vw;
  padding: 10px;
  background-color: #6acfbc;
  background: linear-gradient(to right, rgb(41 41 45), rgb(40 49 87));
  /* background: linear-gradient(to right, rgb(100 100 198), rgb(10 31 82)); */
  /* box-shadow: 0px 2px 20px black; */
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
}

.header-title {
    padding: 8px 16px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: default;
    font-size: 1rem;
}

.menu-button {
  padding: 8px 16px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.menu-button:hover {
  color: rgb(137, 208, 250);
}

.dark-mode-button {
  padding: 8px 16px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

/* Add these media queries at the end of your style section */
@media (max-width: 768px) {
    .chapter-container {
        width: 90%;
    }

    .chapter-content p {
        font-size: 1rem;
    }

    .lesson-link {
        font-size: 1rem;
    }

    #menu-panel {
        width: 200px;
    }
}

@media (max-width: 480px) {
    .chapter-content p {
        font-size: 1 rem;
        line-height: 1.8;
    }

    .lesson-link {
        font-size: 1.5rem;
    }

    #menu-panel {
        width: 70%;
    }

    .close-button {
        font-size: 1.5rem;
    }
    
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: rgb(41 41 45);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.navigation-buttons button:hover {
  background-color: #57b7a6;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .chapter-content p,
.dark-mode .chapter-content h1,
.dark-mode .chapter-content h2,
.dark-mode .chapter-content h3 {
  color: #e0e0e0;
}

.dark-mode .control-bar {
  background: linear-gradient(to right, #1a1a1a, #2c3e50);
}

.dark-mode .navigation-buttons button {
  background-color: #2c3e50;
}

.dark-mode .navigation-buttons button:hover {
  background-color: #34495e;
}

.dark-mode #menu-panel {
  background-color: #1a1a1a;
}

/* Add styles for the new mode toggle button */
.mode-toggle {
  padding: 8px 16px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
}

.mode-toggle:hover {
  color: rgb(137, 208, 250);
}
</style>